 <template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <v-container>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Mensaje enviado
    </div>

    <v-row dense no-gutters class="mt-3">
      <v-col style="width: 300px" cols="auto">
        <PerCatFind id="prueba1" 
          :disabled="this.$store.state.esPartner" 
          class="mb-n3" ref="TipUsrGrp" 
          label="Tipo de usuario / grupo" 
          :perCatIdParent="1" 
          v-model="tipoGrupo" 
          :readonly="true"
          :selectOnReadonly="true"
          :defValue="this.tipoGrupoDefVal">
        </PerCatFind>
        <!-- <PerCatFind class="mb-n3" ref="TipUsrGrp" label="Tipo de usuario / grupo" :perCatIdParent="1" v-model="tipoGrupo" :readonly="true" defValue="101" ></PerCatFind> -->
        <!-- <PerCatFind class="mb-n3" ref="TipUsrGrp" label="Tipo de usuario / grupo" :perCatIdParent="1" v-model="tipoGrupo" :readonly="true" defValue="101" @change="perCatChange" ></PerCatFind> -->
      </v-col>
      <v-col class="mt-n5 ml-2" cols="auto">
          <v-radio-group
            v-show="!this.$store.state.esPartner"
            class="mb-n3"
            style="width: 65px"           
            mandatory
            dense
            v-model="tipoMensaje"
            @change="tipoMensajeChange"
          >
            <v-radio
              label="Individual"
              value="Individual"
              class="mb-n1"
            >
            </v-radio>
            <v-radio
              label="Grupo"
              value="Grupo"
            >
            </v-radio>
          </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense no-gutters>
      <v-col cols="auto"> 
        <PerFind 
          class="mb-n4" ref="paraUsuarioCtrl" v-show="esMensajeIndividual" 
          label="Individual" 
          :perCatIdAnyLevel="tipoGrupo.PerCatId" 
          :perIdRegistrado="$store.state.perId" 
          tipoFiltro="MENSAJES_PERMITIDOS" 
          v-model="paraUsuario" 
          :readonly="enviado" 
          multiple smallChips deletableChips>
        </PerFind>
      </v-col>
      <v-col cols="auto">
        <PerCatFind 
          class="mb-n4" ref="paraGrupoCtrl" v-show="esMensajeGrupal" 
          label="Grupo" 
          :perCatIdParent="tipoGrupo.PerCatId" 
          tipoFiltro="MENSAJES_PERMITIDOS" 
          :perIdRegistrado="$store.state.perId" 
          v-model="paraGrupo" 
          :readonly="enviado" 
          multiple smallChips deletableChips>
        </PerCatFind>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ml-2" color="primary"
          :disabled="loading"
          @click="verDestinatarios"
        >
          Destinatarios
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="9" md="5" lg="4" xl="3">
        <MsjCatFind class="mb-n4" label="Categoría" v-model="msjCat" :readonly="enviado"></MsjCatFind>
      </v-col>

      <v-col cols="12" sm="9" md="5" lg="4" xl="3">
        <PerCatFind class="mb-n4" label="Tipo de lector" perCatIdParent="101" v-model="lectorPerCat" v-show="$store.state.esDepartamento" ></PerCatFind>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12" sm="12" md="9" lg="7" xl="6">
        <v-text-field
          v-model="asunto"
          class="mb-n3"
          outlined
          dense
          :rules="asuntoRules"
          maxlength="50"
          label="Asunto"
          required
          ref="asuntoCtrl"
          :readonly="enviado"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row style="height:370px" no-gutters>
      <v-col>
        <editor
        
          api-key="dw69xsmlltsw13yowzz6fz9ltlfxsggcp3jw71uzxu3kimlj"
          v-model="textoMensaje"
          :init="{
            height: 370,
            menubar: false,
            browser_spellcheck: true,
            contextmenu: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
              link unlink image media | alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help | code'
          }"
          ref="textoMensajeCtrl"
          class="validate[required]"
          :disabled="enviado"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4" no-gutters>
      <v-col cols="auto" >
        <v-file-input
            dense
            hide-input
            truncate-length="0"
            multiple
            @change="changeFile"
            style="width: 40px"
            :disabled="enviado"
        ></v-file-input>
      </v-col>

      <v-col cols="auto">
        <v-simple-table dense style="width: 500px">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in Adjuntos"
                :key="item.FicNom"
              >
                <td>{{ item.FicNom }}</td>
                <td><v-btn @click="deleteFile(item.FicNom)" icon :disabled="enviado"><v-icon>mdi-close</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row style="height:60px" no-gutters>
      <v-col>
      <div class="text-center">
          <v-btn
            color="primary"
            class="mt-4 mr-2"
            @click="enviar"
            style="width:100px"
            ref="enviarBtn"
            :disabled="enviado"
            :loading="loading"
            >
            Enviar
          </v-btn>

          <v-btn
            color="normal"
            class="mt-4 mr-2"
            @click="grabar"
            style="width:100px"
            ref="aceptarBtn"
            :disabled="enviado"
            :loading="loading"
            >
            Aceptar
          </v-btn>

          <v-btn
            color="normal"
            class="mt-4"
            @click="cancelar"
            style="width:100px"
            ref="cancelarBtn"
            >
            Cancelar
          </v-btn>
      </div>
      </v-col>
    </v-row>
    <v-row class="mt-10" no-gutters>
      <v-col class="ml-auto" cols="auto">
        <v-text-field
          outlined
          dense
          v-model="fecha"
          label="Fecha"
          style="height:40px; width:160px"
          class="caption mt-1 mr-2 ml-auto"
          readonly
        ></v-text-field>

        <v-checkbox
          dense
          v-model="enviado"
          label="Enviado"
          style="height:31px; width:100px"
          class="mt-1 mr-2 ml-auto"
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>

  </v-container>
  <v-dialog
    v-model="showDestinatariosDialog"
    transition="dialog-bottom-transition"
    eager
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Destinatarios
      </v-card-title>
      <v-card-text class="mt-2">
        <DestinatariosVisualizacion ref="destVis" :indList="paraUsuario" :gruList="paraGrupo"></DestinatariosVisualizacion>
      </v-card-text>
      
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="showDestinatariosDialog = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-form>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import axios from "axios";
  import { mapState } from "vuex";
  import router from '../router';
  import MsjCatFind from "../components/MsjCatFind.vue";
  import PerCatFind from "../components/PerCatFind.vue";
  import PerFind from "../components/PerFind.vue";
  import DestinatariosVisualizacion from "../components/DestinatariosVisualizacion.vue";

  // import func from '../../vue-temp/vue-editor-bridge';

  export default {
    components: {
       'editor': Editor,
       MsjCatFind, 
       PerCatFind,
       PerFind,
       DestinatariosVisualizacion
     },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('MensajesEnviados', ['entidadActual']),
    },
    data: () => ({
      valid: true,
      loading: false,
      fecha: '',
      enviado: false,
      paraUsuario: [],
      tipoMensaje: 'Individual',
      esMensajeIndividual: true,
      esMensajeGrupal: false,
      tipoGrupo: {},
      paraGrupo: [],
      tipoGrupoDefVal: 101,
      asunto: '',
      asuntoRules: [
        v => !!v || 'El asunto es obligatorio',
        v => v.length <= 50 || 'El asunto no puede tener más de 50 caracteres'
      ],
      textoMensaje: '',
      items: [],
      Adjuntos: [],
      msjCat: {},
      lectorPerCat: {},
      showDestinatariosDialog: false
    }),
    watch: {
    },

    methods: {
      // tipo_change() {
      //   this.per
      // },
      validar () {
        this.$refs.form.validate()
        var ValRet = this.valid

        if (this.valid) {
          if (this.paraUsuario.length == 0 && this.paraGrupo.length == 0) {
            alert('Debe haber al menos un destinatario del mensaje')
            ValRet = false;
          }
          else {
            if (this.textoMensaje.length == 0) {
              alert('El texto no puede estar vacío');
              ValRet = false;
            }
          }
        }
        return ValRet;
      },      
      changeFile: function(fileArray) {
        var itemKey = 0;
        fileArray.forEach(element0 => {
          itemKey--;

          let frmData = new FormData();
          frmData.append('file', element0);

          var raizUrl = this.urlRaiz;

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": raizUrl + "/api/file", "data": frmData, "headers": {"content-type": "multipart/form-data", "Authorization": AuthToken }}).then(result => {
            if (result.status >= 200 && result.status <= 299) {
              var objFic = {MsjFicId: itemKey, MsjId: this.entidadActual.MsjId, FicNom: element0.name, FicNomReal: result.data};
              this.Adjuntos.push(objFic);
            }
            else {
              alert(result.statusText);
            }
          });
        });
      },
      deleteFile (AdjuntoFicNom) {
        var objFoundIndex = this.Adjuntos.findIndex(x=>x.FicNom == AdjuntoFicNom)

        if (objFoundIndex >= 0) {
          this.Adjuntos.splice(objFoundIndex, 1)
        }
      },
      grabar () {
        if (this.loading) { return; }
        if (this.validar())
        {
          this.loading = true;

          this.entidadActual.MsjNom = this.asunto;
          this.entidadActual.MsjTxt = this.textoMensaje;

          // Inicio de conversión para que el backend no grabe los tablas relacionadas
          var MsjPer = [];
          this.paraUsuario.forEach(element => {
            var msjPerObj = {MsjId: this.entidadActual.MsjId, PerId: element.PerId, MsjLeido: element.MsjLeido}
            MsjPer.push(msjPerObj);
          });

          this.entidadActual.MsjPer = MsjPer;

          var MsjPerCat = [];
          this.paraGrupo.forEach(element => {
            var msjPerCatObj = {MsjPerCatId: element.MsjPerCatId, MsjId: this.entidadActual.MsjId, PerCatId: element.PerCatId};

            MsjPerCat.push(msjPerCatObj);
          });

          var MsjFic = [];

          this.Adjuntos.forEach(element => {
            var msjFicObj = {MsjFicId: element.MsjFicId, MsjId: this.entidadActual.MsjId, FicNom: element.FicNom, FicNomReal: element.FicNomReal}
            MsjFic.push(msjFicObj);
          })

          this.entidadActual.MsjFic = MsjFic;

          if (this.msjCat != null) {
            var MsjCatMsj = [];
            var msjCatMsjObj = { MsjCatMsjId: this.msjCat.MsjCatMsjId, MsjId: this.entidadActual.MsjId, MsjCatId: this.msjCat.MsjCatId }
            MsjCatMsj.push(msjCatMsjObj);
          }

          this.entidadActual.MsjCatMsj = MsjCatMsj;

          if (this.lectorPerCat != null) {
            var MsjLecCnd = [];
            var msjLecCndObj = { MsjLecCndId: this.lectorPerCat.MsjLecCndId, MsjId: this.entidadActual.MsjId, PerCatId: this.lectorPerCat.PerCatId }
            MsjLecCnd.push(msjLecCndObj);
          }

          this.entidadActual.MsjLecCnd = MsjLecCnd;

          // Final de conversión para que el backend no grabe los tablas relacionadas

          this.entidadActual.MsjPerCat = MsjPerCat;

          delete this.entidadActual.MsjFecFormatted;
          delete this.entidadActual.MsjPara;
          delete this.entidadActual.MsjLeido;
          delete this.entidadActual.FecLec;

          var controllerParameters = {
              Action: 'SAVE_MENSAJE',
              Msj: this.entidadActual,
              Desarrollo: this.$store.state.desarrollo
          };

          var AuthToken = localStorage.getItem('token');
          // axios({ method: "PUT", "url": this.urlRaiz + "/api/mensajes", "data": entidadJson, "headers": { "content-type": "application/json" } })
          axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
              .then(result => {
                this.loading = false;
                if (result.status >= 200 && result.status <= 299) {
                  if (this.entidadActual.MsjEnv == true) {
                    alert('Mensaje enviado con éxito');
                  }
                  else {
                    alert('Mensaje guardado, pendiente de envío');
                  }
                  router.push('/enviar-mensajes');
                }
              })
              .catch(error => {
                  this.loading = false;
                  alert(error);
              })
        }
      },
    cancelar() {
      router.push('/enviar-mensajes');
    },
    enviar() {
      if (this.loading) { return; }
      if (this.validar())
      {
        this.entidadActual.MsjEnv = true;
        this.grabar();
      }
    },
    // perCatChange() {
    // },
    tipoMensajeChange() {
      if (this.tipoMensaje == 'Individual') {
        this.esMensajeIndividual = true;
        this.esMensajeGrupal = false;
      }
      else {
        this.esMensajeIndividual = false;
        this.esMensajeGrupal = true;
      }
    },
    verDestinatarios() {
      // Usar eager en el v-dialog para tener disponible la ref
      this.showDestinatariosDialog = true;
      this.$refs.destVis.loadData();
    }
  },
  created() {
    if (this.$store.state.esPartner) {
      this.tipoGrupoDefVal = 103
    }
    else {
      this.tipoGrupoDefVal = 101
    }
    this.tipoGrupo = {PerCatId: this.tipoGrupoDefVal};
  },
  mounted() {
    this.loading = true;
    this.fecha = this.entidadActual.MsjFecFormatted;
    if (this.fecha == null || this.fecha == '') {
      this.fecha
    }
    this.enviado = this.entidadActual.MsjEnv;
    this.paraUsuario = this.entidadActual.MsjPer;
    // if (this.paraUsuario == null)    {
    //   this.paraUsuario = [];
    // }

    this.paraGrupo = this.entidadActual.MsjPerCat;
    this.msjCat = this.entidadActual.MsjCatMsj[0];
    this.lectorPerCat = this.entidadActual.MsjLecCnd[0];
    // var objCat = { MsjCatId: this.entidadActual.MsjCatMsj[0].MsjCatId, MsjCatNom: this.entidadActual.MsjCatMsj[0].MsjCatNom } ;
    // this.msjCat = objCat;
    // this.msjCat = this.entidadActual.MsjCatMsj;

    // if (this.paraGrupo == null) {
    //   this.paraGrupo = [];
    // }

    // this.items = this.entidadActual.MsjPer;

    this.asunto = this.entidadActual.MsjNom;
    this.textoMensaje = this.entidadActual.MsjTxt;

    if (this.entidadActual.MsjFic != undefined && this.entidadActual.MsjFic != null) {
      this.Adjuntos = this.entidadActual.MsjFic;
    }
    this.loading = false;
  }
}
</script>