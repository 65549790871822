<template>
    <div class="px-1 mt-1">
      <v-tabs v-model="tab" background-color="transparent" show-arrows>
        <v-tabs-slider color="blue"></v-tabs-slider>
        <v-tab href="#tab-ind">Individual</v-tab>
        <v-tab href="#tab-gru">Grupo</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item :value="'tab-ind'">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                  <v-data-table
                      :headers="indHeaders"
                      :items="indDataRows"
                      :options.sync="indOptions"
                      :footer-props="{itemsPerPageOptions: [100]}"
                      :server-items-length="indTotalDataRows"
                      :loading="indLoading"
                      style="overflow: scroll"
                      mobile-breakpoint="0"
                      class="elevation-1"
                      dense
                  >
                  </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-gru'">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                  <v-data-table
                      :headers="gruHeaders"
                      :items="gruDataRows"
                      :options.sync="gruOptions"
                      :footer-props="{itemsPerPageOptions: [100]}"
                      :server-items-length="gruTotalDataRows"
                      :loading="gruLoading"
                      show-expand
                      single-expand
                      :expanded-sync="gruExpanded"
                      item-key="PerCatId"
                      style="overflow: scroll"
                      mobile-breakpoint="0"
                      class="elevation-1"
                      dense
                  >
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length - 2">
                      </td>
                      <td :colspan="2">
                        <table v-for="(perNom, indexPer) in item.PerNom" :key="indexPer">
                          <tr>
                            <td>{{ perNom }}</td>
                          </tr>
                        </table>
                      </td>
                    </template>
                  </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    components: {
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    props: ['indList', 'gruList'],
    data () {
        return {
            tab: null,
            // Tabla individual
            indLoading: false,
            indTotalDataRows: 0,
            indDataRows: [],
            indOptions: {itemsPerPage: 100},
            indHeaders: [
              { text: 'Tipo', width: 50, sortable: false, value: 'PerTipo' },
                { text: 'Nombre', width:300, sortable: false, value: 'PerNom' }
            ],
            // Tabla grupos
            gruLoading: false,
            gruExpanded: [],
            gruTotalDataRows: 0,
            gruDataRows: [],
            gruOptions: {itemsPerPage: 100},
            gruHeaders: [
                { text: 'Tipo', width: 50, sortable: false, value: 'PerCatTipo' },
                { text: 'Grupo', width:260, sortable: false, value: 'PerCatNom' },
                { text: 'Nombre', width:325, sortable: false, value: 'PerNomString' },
                { text: '', value: 'data-table-expand'}
            ],
        }
    },
    methods: {
      getIndDataFromApi() {
        this.indLoading = true;

        const perIds = this.indList.map(m => m.PerId);
        // var NumRegsPag = this.gruOptions.itemsPerPage;
        // var NumPag = this.gruOptions.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PERS_MSJ_INFO',
            // NumRegsPag: NumRegsPag,
            // NumPag: NumPag,
            PerIds: perIds,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {

            let items = result.data.EntsList;
            // const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.indLoading = false
                resolve({
                items,
                // total,
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.indLoading = false;
                alert("Error al cargar los destinatarios individuales");
            }
          });
        })
      },
      getGruDataFromApi() {
        this.gruLoading = true;

        const perCatIds = this.gruList.map(m => m.PerCatId);
        // var NumRegsPag = this.gruOptions.itemsPerPage;
        // var NumPag = this.gruOptions.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PERCATS_MSJ_INFO',
            // NumRegsPag: NumRegsPag,
            // NumPag: NumPag,
            PerCatIds: perCatIds,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {

            let items = [];
            
            result.data.EntsList.forEach(item => {
              let destinatarios = '';
              if(item.PerNom.length == 1) {
                destinatarios = item.PerNom[0];
              }
              else if (item.PerNom.length > 1) {
                destinatarios = item.PerNom[0] + ', ...';
              }

              const newItem = {
                PerCatId: item.PerCatId,
                PerCatTipo: item.PerCatTipo,
                PerCatNom: item.PerCatNom,
                PerNom: item.PerNom,
                PerNomString: destinatarios
              }
              items.push(newItem);
            });
            // const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.gruLoading = false
                resolve({
                items,
                // total,
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.gruLoading = false;
                alert("Error al cargar los grupos");
            }
          });
        })
      },
      loadData() {
        this.loadIndividualData();
        this.loadGrupoData();
      },
      loadIndividualData() {
        this.indDataRows = [];
        this.indTotalDataRows = 0;
        // this.indDataRows = this.indList;
        // this.indTotalDataRows = this.indList.length;
        this.getIndDataFromApi()
        .then(data => {
          this.indDataRows = data.items;
          // this.indTotalDataRows = data.total
          this.indTotalDataRows = data.items.length;
        });
      },
      loadGrupoData() {
        this.gruDataRows = [];
        this.gruTotalDataRows = 0;
        this.gruExpanded = [];
        // this.gruDataRows = this.gruList;
        // this.gruTotalDataRows = this.gruList.length;
        this.getGruDataFromApi()
        .then(data => {
          this.gruDataRows = data.items;
          // this.gruTotalDataRows = data.total
          this.gruTotalDataRows = data.items.length;
        });
      }
    },
})
</script>
